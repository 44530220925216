//
// Wizard 3
// Pages SASS files are compiled into separate css files
//

// Initialization of global variables, mixins and functions
@import "../../init";

// Base
.wizard.wizard-3 {
	width: 100%;

	.form-group {
		margin-bottom: 1rem;
	}
	// Nav
	.wizard-nav {
		// Steps
		.wizard-steps {
			display: flex;
			align-items: flex-end;

			// Step
			.wizard-step {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-wrap: wrap;
				flex-grow: 1;
				margin-right: 1rem;

				&:last-child {
					margin-right: 0;
				}

				.wizard-label {
					flex: 1;
					display: flex;
					flex-direction: column;
					color: $text-muted;
					padding: 2rem 0.5rem;
					cursor: pointer;

					.wizard-title {
						font-size: 1.1rem;
						font-weight: 600;
						margin-bottom: 0.5rem;
						flex-wrap: wrap;

						span {
							font-size: 2rem;
							margin-right: 0.5rem;
						}
					}

					.wizard-bar {
						height: 4px;
						width: 100%;
						background-color: $gray-200;
						position: relative;

						&:after {
							content: " ";
							position: absolute;
							top: 0;
							left: 0;
							height: 4px;
							width: 0;
							background-color: transparent;
							transition: $transition-link;
						}
					}
				}

				&.current {
					.wizard-label {
						color: $primary;

						.wizard-bar {
							&:after {
								width: 100%;
								background-color: $primary;
							}
						}
					}
				}
			}
		}
	}
}

// portrait tablet and mobile mode
@include media-breakpoint-down(md) {
	.wizard.wizard-3 {
		// Nav
		.wizard-nav {
			.wizard-steps {
				flex-direction: column;
				align-items: flex-start;

				.wizard-step {
					flex: 0 0 100%;
					position: relative;
					width: 100%;

					.wizard-label {
						justify-content: flex-start;
						flex: 0 0 100%;
						padding: 1rem 0;
					}
				}
			}
		}
	}
}
